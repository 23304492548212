<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 944px">
      <div class="layer_head">
        <strong class="tit_popup">공용결재선</strong>
      </div>
      <div class="layer_body">
        <!-- <div class="outer_tbl"> -->
        <div class="tbl_comm tbl_list">
          <table>
            <!-- tbl_approval -->
            <caption class="ir_caption">
              공용결재선 목록 표
            </caption>
            <colgroup>
              <col style="width: 52px" />
              <col style="width: 84px" />
              <col style="width: 236px" />
              <col style="width: 436px" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">순번</th>
                <th scope="col">공용 결재선 명</th>
                <th scope="col">처리부서 결재선</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(publicApprovalLine, idx) in publicApprovalLineList"
                :key="publicApprovalLine.rownum"
                :class="{
                  tr_select: publicApprovalLine == publicApprovalLineData,
                }"
              >
                <td>
                  <div class="box_choice type_alone">
                    <!-- 텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 -->
                    <div class="item_choice">
                      <input
                        :id="'select_' + idx"
                        v-model="publicApprovalLineData"
                        type="radio"
                        class="inp_choice"
                        :value="publicApprovalLine"
                        name="selectLine"
                      />
                      <label class="lab_choice" :for="'select_' + idx">
                        <span class="ico_account ico_rdo" />
                      </label>
                    </div>
                  </div>
                </td>
                <td>{{ idx + 1 }}</td>
                <td class="cell_left">
                  <span class="txt_detail">{{ publicApprovalLine.name }}</span>
                </td>
                <td class="cell_left">
                  <span class="txt_detail">{{ processing[idx] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- </div> -->
      </div>
      <div class="layer_foot">
        <div
          class="paging_comm"
          :class="
            !publicApprovalLineList.length || publicApprovalLineList.length === 0
              ? 'paging_none'
              : ''
          "
        >
          <paginate
            v-model="paginateConfig.pageCurrent"
            :click-handler="setPaging"
            :container-class="paginateConfig.ulClass"
            :prev-text="paginateConfig.prevText"
            :next-text="paginateConfig.nextText"
            :page-range="paginateConfig.pageRange"
            :margin-pages="paginateConfig.marginPage"
            :page-count="paginateConfig.pageCount"
          />
        </div>
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button type="button" class="btn_medium btn_secondary" @click.prevent="setApproval()">
            적용
          </button>
        </div>
        <!-- <a href="javascript:void(0);" class="link_close" @click.prevent="closeLayer">
        <span class="ico_account">닫기</span>
      </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import { default as CommPaginateMixin } from "@/_approval/mixins/common/commPaginate.js";
export default {
  name: "PopPublicApprovalLine",
  mixins: [CommLayerMixin, CommMomentMixin, CommPaginateMixin],
  data() {
    return {
      publicApprovalLineList: [],
      empNo: "",
      totalCount: 0,
      publicApprovalLineData: {},
    };
  },
  computed: {
    processing: function () {
      return this.publicApprovalLineList.map(function (item) {
        return item.deptLine.replaceAll(",", " > ");
      });
    },
    // reference: function () {
    //   return this.publicApprovalLineList.map(function (item) {
    //     let referenceTxt = item.approvalLine.split('|')[1]
    //     if (referenceTxt !== undefined) {
    //       return referenceTxt.replace('>', ', ')
    //     } else {
    //       return ''
    //     }
    //   })
    // }
  },
  created() {
    this.paginateConfig.pageCurrent = 1;
    this.setPaging(this.paginateConfig.pageCurrent);
  },
  methods: {
    setPaging(page) {
      this.paginateConfig.pageCurrent = page; // 선택한 페이지 번호 값 세팅 (필수)
      this.selectPublicApprovalLineList(this.paginateConfig.pageCurrent);
    },
    async selectPublicApprovalLineList(pageNo) {
      const path = `${this.$apiPath.APRVL_PBLC_APRVL_LINE}`;
      const params = { page: pageNo, size: 10 };
      const result = await ApiService.shared.get(path, { params });

      if (!result.data) {
        this.publicApprovalLineList = [];
        return;
      }

      this.publicApprovalLineList = result.data;
      this.paginateConfig.pageCount = Number(result.total);
    },

    setApproval() {
      // 적용 클릭
      this.$emit("setApproval", this.publicApprovalLineData);
      // this.$PopPublicAppBus.$emit('set-approval', this.publicApprovalLineData)
      // this.closeLayer()
    },
  },
};
</script>
