<template>
  <div id="mArticle">
    <div class="head_section">
      <div class="wrap_btn">
        <div class="pos_left">
          <h2 id="dkBody" class="tit_section">결재선 관리</h2>
        </div>
        <div class="pos_right">
          <button
            type="button"
            class="btn_medium btn_secondary"
            @click.prevent="publicApplovalLinePopup"
          >
            결재선 검색
          </button>
        </div>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <!-- admin 타입 type_admin -->
        <caption class="ir_caption">
          결재선 계정 관리 표
        </caption>
        <colgroup>
          <col style="width: 160px" />
          <col />
          <!-- 2019-08-22 수정 -->
          <col style="width: 160px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">결재선 명 <sup>*</sup></th>
            <!--        <td colspan="3" v-if="isEdit">{{payAccount.payAccountName}}</td>-->
            <td colspan="3">
              <Input :value.sync="payAccount.payAccountName" :maxLength="100" />
            </td>
          </tr>
          <tr>
            <th scope="row">결재선 활성화 여부</th>
            <td colspan="3">
              <div class="box_choice">
                <div class="item_choice">
                  <input
                    id="active1"
                    v-model="payAccount.useYn"
                    type="radio"
                    class="inp_choice"
                    value="true"
                    name="active"
                    checked
                  /><!-- disabled 상태시 disabled 클래스 추가 -->
                  <label class="lab_choice" for="active1">
                    <span class="ico_account ico_rdo" />활성화
                  </label>
                </div>
                <div class="item_choice">
                  <input
                    id="active2"
                    v-model="payAccount.useYn"
                    type="radio"
                    class="inp_choice"
                    value="false"
                    name="active"
                  /><!-- disabled 상태시 disabled 클래스 추가 -->
                  <label class="lab_choice" for="active2">
                    <span class="ico_account ico_rdo" />비활성화
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">결재선</th>
            <td class="va_top">
              <!-- 2019-06-13 클래스 수정 -->
              <!-- <div class="wrap_btn">
              <div class="pos_left">
              </div>
            </div> -->
              <div
                v-if="procDeptApprLine.length > 0"
                ref="procDeptApprLine"
                class="group_people type_fixed"
              >
                <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
                <comm-item-emp
                  v-for="emp in procDeptApprLine"
                  :key="emp.empNo"
                  :empName="emp.empName"
                  :accountId="emp.accountId"
                  :isTypeFix="true"
                  :isDisableBtn="true"
                />
              </div>
            </td>
            <th scope="row">참조자</th>
            <td class="va_top">
              <!-- 2019-06-13 클래스 수정 -->
              <div ref="referenceApprovalLine" class="group_people type_fixed">
                <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
                <comm-item-emp
                  v-for="emp in referenceApprovalLine"
                  :key="emp.empNo"
                  :empName="emp.empName"
                  :accountId="emp.accountId"
                  :isTypeFix="true"
                  :isDisableBtn="true"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList()">
          취소
        </button>
      </div>
      <div class="pos_right">
        <button
          v-if="isEdit"
          type="button"
          class="btn_large btn_secondary"
          @click="onClickremovePayAccount"
        >
          삭제
        </button>
        <button type="button" class="btn_large btn_primary" @click.prevent="confirmSavePayAccount">
          저장
        </button>
      </div>
    </div>
    <PopPublicApprovalLine
      v-if="isPopUpOpen"
      @setApproval="setApproval"
      @onClickClose="onClickClose"
    />
  </div>
</template>

<script>
// import axios from 'axios'
import ApiService from "@/services/ApiService";

import CommOpt from "@/_approval/components/common/CommOpt";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommItemEmp from "@/_approval/components/common/CommItemEmp";
import Input from "@/_approval/components/common/input/Input";
import PopPublicApprovalLine from "@/_approval/components/admin/popup/PopPublicApprovalLine";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import Vue from "vue";

Vue.prototype.$PopPublicAppBus = new Vue();

export default {
  name: "PayAccountEdit",
  components: {
    CommOpt,
    CommInpSearch,
    CommItemEmp,
    Input,
    PopPublicApprovalLine,
  },
  mixins: [CommLayerMixin, CommFlatpickrMixin, CommMomentMixin],
  data() {
    return {
      isEdit: false,
      isPopUpOpen: false,
      empNo: "",
      useYn: null,
      payAccountName: "",
      payAccountList: [],
      aprvlLineAccntNo: "",
      procDeptApprLine: [],
      referenceApprovalLine: [],
      publicApprovalLine: "",
      payAccount: {
        payAccountName: "",
        useYn: "Y",
      },
      pubApprLineId: "",
      empCorpCardModifyYn: "",
    };
  },
  created() {
    if (this.$route.query.aprvlLineAccntNo) {
      this.isEdit = true;
      this.aprvlLineAccntNo = this.$route.query.aprvlLineAccntNo; // 파라미터 가져와 데이터 셋팅
      this.selectPayAccount();
    }
  },
  methods: {
    async selectPayAccount() {
      // 해당 계정 조회
      const path = `${this.$apiPath.APRVL_LINE_ACCONT}/` + this.aprvlLineAccntNo;
      const result = await ApiService.shared.getData(path);

      if (!result.data) return;
      const obj = this;
      let procDeptApprLine = [];
      this.procDeptApprLine = [];
      let referenceApprovalLine = [];
      this.referenceApprovalLine = [];

      this.payAccountName = result.data.name;
      this.useYn = result.data.useYn;
      this.pubApprLineId = result.data.cmmnAprvlLine.cmmnAprvlLineNo;

      this.payAccount.payAccountName = result.data.name;
      this.payAccount.useYn = result.data.useYn;

      result.data.cmmnAprvlLine.cmmnAprvlLineTargetList.map(function (item, idx) {
        if (item.aprvlLineType === "A") {
          // 결재자
          const emp = {};
          emp.empNo = item.empNo;
          emp.empName = item.empName;
          emp.accountId = item.accountId;
          emp.approvalType = item.aprvlLineType;
          emp.aprvlOrder = idx + 1;
          procDeptApprLine.push(emp);
          obj.procDeptApprLine = procDeptApprLine;
        } else {
          // 참조자
          const emp = {};
          emp.empNo = item.empNo;
          emp.empName = item.empName;
          emp.accountId = item.accountId;
          emp.approvalType = item.aprvlLineType;
          emp.aprvlOrder = idx + 1;
          referenceApprovalLine.push(emp);
          obj.referenceApprovalLine = referenceApprovalLine;
        }
      });

      // axios.post('/api/admin/selectPayAccount', {
      //   aprvlLineAccntNo: this.aprvlLineAccntNo
      // })
      //   .then((res) => {
      //     this.payAccount = res.data
      //     this.procDeptApprLine = res.data.procApprovalLine
      //     this.referenceApprovalLine = res.data.ccApprovalLine
      //     this.pubApprLineId = res.data.pubApprLineId
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    setApproval(publicApprovalLineData) {
      this.isPopUpOpen = false;
      this.publicApprovalLine = publicApprovalLineData;

      const publicApprovalLine = this.publicApprovalLine;
      this.pubApprLineId = publicApprovalLine.cmmnAprvlLineNo;
      let procDeptApprLineTxt = publicApprovalLine.deptLine;
      this.procDeptApprLine = [];
      this.addApprovalLine(procDeptApprLineTxt, "A");

      if (publicApprovalLine.refName) {
        let referenceApprovalLineTxt = publicApprovalLine.refName;
        this.referenceApprovalLine = [];
        this.addApprovalLine(referenceApprovalLineTxt, "R");
      }
    },
    addApprovalLine(txt, type) {
      // 선택된 공용결재선의 결재선과 참조자 세팅
      return txt.split(",").forEach((value, index) => {
        let accountId = value.split("(")[0];
        let empName = value.split("(")[1].replace(")", "").trim();
        if (type === "A") {
          this.procDeptApprLine.push({
            empName: empName,
            accountId: accountId,
            approvalType: "A",
          });
        } else {
          this.referenceApprovalLine.push({
            empName: empName,
            accountId: accountId,
            approvalType: "R",
          });
        }
      });
    },
    onClickClose() {
      this.isPopUpOpen = false;
    },
    updateSavePayAccount() {
      // 계정 수정/등록
      if (this.isEdit) {
        //수정
        this.updatePayAccount();
      } else {
        //등록
        this.savePayAccount();
      }
      // axios.post('/api/admin/insertPayAccount', {
      //   aprvlLineAccntNo: payAccount.aprvlLineAccntNo,
      //   payAccountName: payAccount.payAccountName,
      //   useYn: payAccount.useYn,
      //   pubApprLineId: this.pubApprLineId,
      //   // 자산관리 동기화 때문에 추가
      //   displayOrder: payAccount.displayOrder,
      //   insertEmpNo: payAccount.insertEmpNo
      // })
      //   .then((res) => {
      //     this.$router.push('/admin/PayAccountList')
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    async updatePayAccount() {
      const path = `${this.$apiPath.APRVL_LINE_ACCONT}`;
      const obj = this.getObjToSend();
      const result = await ApiService.shared.put(path, obj);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        return;
      }

      this.gotoList();
    },
    async savePayAccount() {
      const path = `${this.$apiPath.APRVL_LINE_ACCONT}`;
      const obj = this.getObjToSend();
      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        return;
      }
      this.gotoList();
    },
    getObjToSend() {
      let obj = {};
      if (this.isEdit) {
        obj = {
          aprvlLineAccntNo: this.aprvlLineAccntNo,
          name: this.payAccount.payAccountName,
          cmmnAprvlLineNo: this.pubApprLineId,
          useYn: this.payAccount.useYn,
          remark: "",
        };
      } else {
        obj = {
          cmmnAprvlLineNo: this.pubApprLineId,
          name: this.payAccount.payAccountName,
        };
      }

      return obj;
    },
    publicApplovalLinePopup(item) {
      // 공용결재선 선택 레이어
      this.isPopUpOpen = true;
      // this.isOpen = false
      // let layer = {}
      // layer.header = '공용결재선'
      // layer.type = 'popup'
      // layer.class = 'inner_approval_layer' // 팝업 레이어 class 값
      // layer.componentFile = 'admin/popup/PopPublicApprovalLine.vue'
      // this._showLayer(layer, '')
    },
    initApprovalLineData() {
      /* 레이어에서 공용결재선 적용버튼 클릭 시 호출 */
      const publicApprovalLine = this.publicApprovalLine;
      this.pubApprLineId = publicApprovalLine.pubApprLineId;
      let procDeptApprLineTxt = publicApprovalLine.approvalLine; //.split('|')[0]
      let referenceApprovalLineTxt = publicApprovalLine.approvalLine; // .split('|')[1]
      this.procDeptApprLine = [];
      this.addApprovalLine(procDeptApprLineTxt, "A");
      this.referenceApprovalLine = [];
      this.addApprovalLine(referenceApprovalLineTxt, "R");
    },

    confirmSavePayAccount(emp) {
      // 2중 레이어?
      const layer = {};
      layer.type = "confirm";
      layer.header = "결재선 관리"; // 레이어 타이틀
      layer.content = "저장 하시겠습니까?"; // 레이어 내용
      this._showLayer(layer, this.updateSavePayAccount);
    },

    onClickremovePayAccount() {
      const layer = {};
      layer.content = "정말 삭제하시겠습니까?";
      layer.type = "confirm";
      this._showLayer(layer, this.removePayAccount);
    },
    removePayAccount() {
      this.deletePayAccount();
    },
    async deletePayAccount() {
      const path = `${this.$apiPath.APRVL_LINE_ACCONT}/` + this.aprvlLineAccntNo;
      const result = await ApiService.shared.delete(path);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        return;
      } else {
        const path = this.$paymentRouterPath.PAYMENT_SYSTEM_ACCOUNT_LIST;
        this.$router.push({ path: path });
      }
    },
    gotoList() {
      const path = this.$paymentRouterPath.PAYMENT_SYSTEM_ACCOUNT_LIST;
      this.$router.push({ path: path });
    },
  },
};
</script>
